<template>
    <div class="guize">
        <div v-if="guize==0">
            <div>早起打卡赛活动规则</div>
            <p>
                <span> 1.参赛规则 </span> <br>
                <span> 1) 活动简介</span>  <br>
                ①活动目前有普通场1种场次，报名费为1元、10元、20元（或等值彩贝）3个档次。报名后，要求在次日05:00-08:00到早起打卡赛活动页面打卡，即可拿回报名费，并按参赛券比例随机瓜分失败者奖金（以等值彩贝形式发放奖金）；  <br>

                <span> 2)报名规则 </span> <br>
                ●普通场活动支持微信支付和彩贝支付两种方式进行支付。当用户的彩贝余额大于等于所选档次报名费时，方可选择彩贝支付；彩贝可以通过余额兑换到彩贝；  <br>

                <span> 3)打卡规则 </span> <br>
                ①打卡方式：当日05:00-08:00在早起打卡赛活动页面点击立即打卡按钮，完成早起打卡即视为早起成功，否则失败；  <br>
                ②打卡时间：每日打卡时间节点以北京时间为准，若未满足打卡要求，导致挑战失败，将不会退回报名费本金哦，记得每天按时来活动页打卡啦；  <br>

                <span> 4)瓜分规则 </span> <br>
                ①瓜分奖金：根据实际完成情况，挑战成功者将按报名费比例随机瓜分奖金池的所有奖金，挑战失败者将无法获取任何奖金，支付的报名费也不会返还。  <br>
                ②结果通知：瓜分结果会在北京时间08:00:00后由系统统计完毕并发放奖金，用户可在“我的彩贝”中查看，并可在彩贝兑换中兑换成余额，可以申请提现后到账。  <br>

                <span> 2.参与条件 </span> <br>
                1)普通场活动，参与次数不限，完成一个周期的挑战(报名→打卡)后，可继续报名参与新一期的挑战。  <br>

                <span>  3.报名费说明 </span> <br>
                1)报名费是用来帮助用户激励自己坚持早起的一种手段，用户支付报名费报名，只需按照各档次要求通过活动页05:00-08:00到早起打卡赛活动页面打卡行为即可收回全部的报名费本金，并随机瓜分失败者的报名费；  <br>
                2)用户可以自行选择报名费的金额档次，目前包含1元、10元、20元共3个档位；  <br>
                3)若当期参与的用户全部挑战成功，则报名费全部退还给参与用户，不额外瓜分报名费。  <br>

                <span>  4.违规说明 </span> <br>
                对于采用不正当手段(包括但不限于作弊、扰乱系统、实施网络攻击等)参与活动的用户，本平台有权不予发放奖励并追回历史奖励(包括但不限于报名费、奖金等)。  <br>

                <span>5.活动时间</span>  <br>
                本期活动时间：截止日将会提前通过系统公告告知。  <br>

               <span> 6.其他说明</span>  <br>
                1)本平台对误发奖励保留追索或划扣的权利，您若参与本次活动，即代表接受本次活动的所有规则及用户协议。  <br>
                2)如对本活动规则有任何疑问，可通过APP内“我的-专属客服” 联系客服咨询我们。  <br>
            </p>
        </div>
        <div v-else>
            <div>早起打卡赛用户协议</div>
            <p>
                您(用户)在参加本平台举办的“早起打卡赛”下属各项赛事活动(以下简称活动)之前，请认真阅读本《早起打卡赛用户协议》(以下简称协议) ，以确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议，除非您接受本协议所有条款，否则您无权参与活动，一旦参与即视作同意并接受本协议各项条款的约束。<br>
                <span>一、参与规范</span> <br>
                1、登录账号：用户在参与活动前需要注册一个本平台账号，可通过微信号注册等第三方快捷登录等方式完成，已有账号则直接登录即可。<br>
                2、活动规则：用户参与不同活动时，需遵守各活动的详细规则。不同活动的详细规则略有不同，包括但不限于参与条件、活动时间、活动周期、是否能重复参与、是否能同时参与、是否需要支付报名费等，详细规则请参见各项活动的具体规则说明。<br>
                3、行为规范：用户不得利用本活动进行如下行为<br>
                (1)虚构事实、隐瞒真相以误导、欺骗他人；<br>
                (2)利用本活动从事任何违法犯罪活动；<br>
                (3)通过第三方软件、插件、外挂、系统，登录或使用本平台参加活动，并谋取不正当利益。包括但不限于谋取金钱利益、发布不良舆论、堆积垃圾信息、扰乱系统、实施网络攻击等；<br>
                (4)未获得本平台书面许可，擅自对本活动相关页面进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；<br>
                (5)未获得本平台书面许可，擅自对本活动相关的拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；<br>
                (6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br>
                (7)其他违反法律法规规定、侵犯其他用户及本平台合法权益、干扰本平台正常运营或本平台未明示授权的行为。<br>
                <span>二、报名费使用规范</span> <br>
                1、部分活动(如“早起打卡赛”)需要用户支付一定金额作为“报名费”方可参与，用户一旦参与，则视为同意本平台对其进行对应金额的扣款操作。<br>
                <span>2、用户支付的报名费会进入该期活动的奖金池，用户完成挑战 (完成挑战条件可参考具体活动的详细规则)即可收回全部报名费，并瓜分未完成挑战用户的报名费作为额外奖金。</span><br>
                <span>3、未完成挑战的用户将无法获取任何奖金，支付的报名费将作为完成挑战用户的奖金而不会返还。</span><br>
                <span>三、数据管理</span> <br>
                1、本平台不对用户在本活动中相关数据的删除或储存失败负责，用户删除数据前务必做好数据同步、备份等安全操作。<br>
                2、本平台可以根据实际情况自行决定用户在本活动中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本活动中的相关数据。<br>
                3、如用户停止参加本活动或本活动终止，本平台可以从服务器上永久删除用户的数据。本服务停止后，本平台没有义务向用户返还任何数据。<br>
                4、用户在注册及参加本活动时，本平台需要搜集能识别用户身份的个人信息以便在必要时联系用户，或为用户提供更好的体验。本平台搜集的信息包括但不限于用户的账号、昵称、性别、头像等，本平台同意对这些信息的使用将受限于用户个人隐私信息保护的约束。<br>
                <span>四、违规惩处</span> <br>
                1、对于违反本协议及活动规则、采用不正当手段参与活动的用户，本平台有权不予发放活动奖励或追回历史奖励(包括但不限于现金奖励、实物奖励、虚拟奖励等)，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁等处罚。<br>
                2、法律责任：用户理解并同意，本平台有权依合理判断对违反有关法律法规或本协议规定或活动规则的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br>
                <span>五、不可抗力及其他免责事由免责</span> <br>
                1、用户理解并同意，因业务发展需要，本平台保留单方面对本活动的全部或部分内容变更、暂停、终止或撤销的权利。<br>
                2、用户理解并同意，在参加本活动的过程中，可能会遇到不可抗力等风险因素，使本活动发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震等以及社会事件如战争、动乱等。出现上述情况，本平台将努力在第一时间与相关单位配合，及时进行修复，但是由此对用户造成的损失，本平台在法律允许的范围内免责。<br>
                3、用户理解并同意，本活动同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到部分安全问题的侵扰，如他人利用用户的资料等。用户应加强网络信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。<br>
                4、用户理解并确认，本活动存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户任何损失，本平台不承担任何责任。<br>
                5、用户理解并确认，在参加本活动过程中，如存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，本平台不承担任何责任，用户要注意加强网络信息的鉴别。<br>
                6、用户理解并确认，本平台依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成本平台的义务或承诺，本平台不能保证及时发现违法违规或违约行为或进行相应处理。本平台将努力在第一时间与相关单位配合，及时进行修复，但是由此对用户造成的损失，本平台在法律允许的范围内免责。<br>
                <span>六、活动的变更、中断、终止</span> <br>
                鉴于互联网活动的特殊性，用户同意本平台有权随时变更、中断或终止部分或全部的活动(包括支付报名费的活动)。本平台如变更、中断或终止的服务，应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，且该用户已经向本平台支付报名费，本平台应当按照该用户实际情况退还用户的报名费。<br>
                <span>七、其他</span> <br>
                1.本平台郑重提醒用户认真阅读本协议，自主考虑风险，未成年人应在法定监护人的陪同下阅读本协议。<br>
                2.本协议的效力、解释及纠纷的解决，适用于中国人民共和国法律。若用户和本平台之前发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交本平台住所地有管辖权的人民法院管辖。<br>
                3.本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。<br>
                4.本协议可由本平台随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本页面查阅最新版协议条款。在修改协议条款后，用户如果不接受修改后的条款，请立即停止活动，用户继续参加则视为接受修改后的协议。<br>
                5.由于互联网高速发展，您与本平台签署的本协议列明的条款可能并不能完整罗列并覆盖您与本平台所有的权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，各活动附属的详细规则、邀请规则等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您参加本活动，视为您同意上述补充协议。<br>
                6.如对协议由任何疑问，可通过APP “我的-专属管家”咨询我们，或联系客服。<br>
            </p>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return{
                guize:0,
            }
        },
        mounted(){
            this.guize = this.$route.query.guize;
            if(this.$route.query.guize == 0){
                document.title = "早起打卡赛活动规则";
            }else{
                document.title = "早起打卡赛用户协议";
            }
        }
    }
</script>
<style lang="less" scoped>
.guize{
    div{
        padding: 10px;
        box-sizing: border-box;
        div{
            text-align: center;
            font-size: 18px;
            font-weight: bold;
        }
        p{
            font-size: 15px;
            line-height: 30px;
            span{
                font-weight: bold;
            }
        }
    }
}
</style>